
import { Component, Vue, Watch } from 'vue-property-decorator';
import ProjectTopMenu from '@/components/project/ProjectTopMenu.vue';
import QuestionNavigation from '@/components/project/make/question/QuestionNavigation.vue';
import QuestionCategory from '@/components/project/make/question/QuestionCategory.vue';
import Question from '@/components/project/make/question/Question.vue';
import QuestionModalChild from '@/components/project/make/question/QuestionModalChild.vue';
import { QUESTION } from '@/types/question';
import type { IQuestionValue } from '@/interface/survey/question';
import EventBus from '@/utils/EventBus';
import { IQuestionOptions } from '@/interface/survey/question';
import QuestionModalMove from '@/components/project/make/question/QuestionModalMove.vue';
import QuestionModalCopy from '@/components/project/make/question/QuestionModalCopy.vue';
import QuestionModalDelete from '@/components/project/make/question/QuestionModalDelete.vue';
import QuestionModalSave from '@/components/project/make/question/QuestionModalSave.vue';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';
import ScrollTopButton from "@/components/common/ScrollTopButton.vue";
import {ToastVariant} from "@/utils/ToastEnum";

@Component({
  components: {
    ScrollTopButton,
    ProjectTopMenu,
    QuestionNavigation,
    QuestionCategory,
    Question,
    QuestionModalChild,
    QuestionModalMove,
    QuestionModalCopy,
    QuestionModalDelete,
    QuestionModalSave,
    TextBoxModal,
  },
})
export default class Make extends Vue {
  projectId: number = -1;
  isLoading = true;
  dataName: string = '';
  singleView: boolean = false; // 질문지 조회 단일
  selectCategoryIndex: number = -1;
  makeEndTip: string = '';

  projectTitle = '';

  lastCategoryMenu: IQuestionOptions[] = [
    {
      icons: require('@/assets/images/icons/make/example.svg'),
      text: '보기 가져오기',
      value: QUESTION.QUESTION_TYPES.CHILD,
    },
  ];
  categoryList: IQuestionOptions[] = [...QUESTION.QUESTION_TYPES_LIST];
  questionName: Array<string> = [];
  makeList: IQuestionValue[] = [];
  scrolling: boolean = false;
  scrollTimeout: number  = 0;

  notSelectedQuestion: boolean = false;
  $refs: Vue['$refs'] & {
    QuestionNavigation: QuestionNavigation;
  };
  linkConfirmation: number = 0;
  surveyType: number = 0;
  modalTitle: string = '';
  modalDescription: string = '';
  modalName: string = '';
  previewUrl = '';
  modalPropData: QUESTION.VALUE | undefined = {};
  titleCheck = false;
  htmlApp: HTMLElement | null = null;
  naviScroll: boolean = true;

  created(): void {
    this.projectId = Number(this.$route.params.id);
    this.initEventBus();
  }

  async mounted(): Promise<void> {
    await this.load();
    this.htmlApp = document.getElementById('app');
    if (this.htmlApp) this.htmlApp.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroyed() {
    if (this.htmlApp) this.htmlApp.removeEventListener('scroll', this.handleScroll);
  }


  async load(): Promise<void> {
    try {
      await this.$question.initQuestion(this.projectId);
      this.makeList = this.$question.getMakeList();
      this.questionName = this.makeList.map((value) => value.NAME);
      this.surveyType = this.$question.getSurveyType;
      this.previewUrl = this.$question.getTestUrl;
      this.makeEndTip = `${
        this.surveyType === 1 && this.$question.makeUpdateStatus
          ? '설문 편집을 종료하고 결제 화면으로 이동합니다.'
          : '설문 편집을 종료하고 현황 화면으로 이동합니다.'
      }`;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  initEventBus() {
    EventBus.$on(QUESTION.EVENT_FUNCTION.MAKE_QUESTION_RELOAD, this.reload);
  }

  reload(QNAME?: string) {
    if (QNAME) this.makeList = [];
    this.$nextTick(() => {
      this.makeList = this.$question.getMakeList();
      this.questionName = this.makeList.map((value) => value.NAME);
      //저장 후 스크롤 변경을 위해 적용
      if (QNAME) {
        this.dataName = '';
        this.questionSwitch({ QNUM: QNAME, naviScroll: true, notOpen: true });
      }
    });
  }

  // 모달창
  modalController(emit: { type: string; qnum: string, title?: string, description?: string }) {
    const { type, qnum } = emit;
    this.modalName = qnum;
    this.modalPropData = this.makeList.find((v) => v.NAME === this.modalName);
    switch (type) {
      case QUESTION.MODAL_TYPE.MOVE:
        this.$nextTick(() => this.$bvModal.show(`move-modal-${this.modalName}`));
        break;
      case QUESTION.MODAL_TYPE.DELETE:
        this.$nextTick(() => this.$bvModal.show(`delete-modal-${this.modalName}`));
        break;
      case QUESTION.MODAL_TYPE.COPY:
        this.$nextTick(() => this.$bvModal.show(`copy-modal-${this.modalName}`));
        break;
      case QUESTION.MODAL_TYPE.SAVE:
        this.$nextTick(() => this.$bvModal.show(`save-modal-${this.modalName}`));
        break;
      case QUESTION.MODAL_TYPE.CANCEL:
        this.modalName = QUESTION.MODAL_TYPE.CANCEL;
        this.modalTitle = emit?.title || '문항 편집을 종료하시겠습니까?';
        this.modalDescription = emit?.description || '수정했던 내용은 취소됩니다.';
        this.$nextTick(() => this.$bvModal.show(`text-modal-${this.modalName}`));
        break;
      case QUESTION.MODAL_TYPE.ALERT:
        this.modalName = QUESTION.MODAL_TYPE.ALERT;
        this.modalTitle = emit?.title || '문항 편집을 종료하시겠습니까?';
        this.modalDescription = emit?.description || '수정했던 내용은 취소됩니다.';
        this.$nextTick(() => this.$bvModal.show(`text-modal-${this.modalName}`));
        break;
      case QUESTION.MODAL_TYPE.LEAVE:
        this.modalName = QUESTION.MODAL_TYPE.LEAVE;
        this.modalTitle = emit?.title || '문항 편집을 종료하시겠습니까?';
        this.modalDescription = emit?.description || '수정했던 내용은 취소됩니다.';
        this.$nextTick(() => this.$bvModal.show(`modal-leave`));
        break;
      case QUESTION.MODAL_TYPE.END:
        this.modalName = QUESTION.MODAL_TYPE.END;
        this.modalTitle = '설문 편집을 종료하시겠습니까?';
        this.modalDescription = '[설문 저장]을 하지 않으면 변경사항이 반영되지 않습니다. <br/> 변경사항을 저장했는지 확인하시고, 미리보기로 설문을 확인해보세요.';
        this.$nextTick(() => this.$bvModal.show(`text-modal-${this.modalName}`));
        break;
      case 'QUOTA':
        this.$router.push(`/project/quota/${this.projectId}`);
        break;
    }
  }

  closeEditorMode() {
    if (this.modalName === QUESTION.MODAL_TYPE.ALERT || this.modalName === QUESTION.MODAL_TYPE.LEAVE) {
      this.makeList = this.makeList.map((m) => {
        if (m.EDIT) m.EDIT = false;
        return m;
      });
      this.dataName = '';
      if (this.modalName === QUESTION.MODAL_TYPE.ALERT) this.addQuestionCategory();
    } else if (this.modalName === QUESTION.MODAL_TYPE.CANCEL) {
      this.makeList = this.makeList.map((m) => {
        if (m.EDIT) m.EDIT = false;
        return m;
      });
      this.reload();
      this.dataName = '';

    } else {
      this.checkTitle = true;
    }
  }

  getLinkConfirmation(status) {
    this.linkConfirmation = status;
  }

  surveyPreview() {
    const win = window.open(this.previewUrl);
    win?.localStorage.clear();
    win?.location.reload();
  }

  changeSingleView(flag: boolean) {
    this.singleView = flag;
  }
  // 문항 선택
  selectCategory(selectIndex: number) {
    this.selectCategoryIndex = selectIndex;
    if (this.selectCategoryIndex > -1) {
      this.$nextTick(() => {
        const ele = document.getElementById('add-question-button-wrapper');
        if (ele)
          ele.scrollIntoView({
            behavior: 'auto',
            block: 'start',
          });
      });
    }
  }

  @Watch('selectCategoryIndex')
  changeQuestionIndex() {
    if (this.selectCategoryIndex === -1) this.notSelectedQuestion = false;
  }

  // 문항 추가 버튼 (기존 사용 함수)

  editCheck(): boolean {
    const edit = this.makeList.filter(v =>  v.EDIT);
    if (edit.length > 0) {
      const emit = {
        type: QUESTION.MODAL_TYPE.ALERT,
        qnum: '',
        title: '문항 편집을 종료하시겠습니까?',
        description: '[설문 저장]을 하지 않으면 변경사항이 반영되지 않습니다. <br/> 변경사항을 저장했는지 확인해주세요.'
      }
      this.modalController(emit);
      return false;
    }
    return true;
  }

  async addQuestionCategory(): Promise<void> {
    try {
      const index = this.selectCategoryIndex;
      const category: IQuestionOptions = this.categoryList[index];
      if (index === -1) {
        this.$bvToast
        this.$common.makeToast('추가할 문항 유형을 선택해주셔야 합니다.',ToastVariant.DANGER,  this.$bvToast);
        this.notSelectedQuestion = true;
        return;
      }

      if (!this.editCheck()) return;

      this.dataName = '';
      const { value } = category;
      this.notSelectedQuestion = false;
      if (this.lastCategoryMenu.findIndex((item) => item.value === value) > -1) {
        this.$bvModal.show('question-modal-child');
      } else {
        const { result, qnum } = await this.$question.add(value as QUESTION.QUESTION_TYPES);
        if (result) {
          this.$nextTick(() => {
            this.questionSwitch({ QNUM: qnum, naviScroll: false });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  //설문 전체 저장
  questionSave() {
    this.isLoading = true;
    this.checkTitle = true;
  }

  async save() {
    try {
      const sendData = {title: this.projectTitle}
      const result = await this.axios.put(`/project/make/save/title/${this.projectId}`,sendData);
      setTimeout(() => {
        this.$bvModal.hide('alert-modal');
      }, 200)
      if (result) {
        await this.$question.setSurveySave();
        const url = this.surveyType === 1 ? `/project/payment/${this.projectId}` : `/project/quota/${this.projectId}`;
        await this.$router.push(url);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async dataSave(emit: boolean) {
    try {
      if (emit && this.modalName === QUESTION.MODAL_TYPE.END) this.$nextTick(() => this.$bvModal.show('alert-modal'))
      if (!emit && this.modalName !== QUESTION.MODAL_TYPE.END) {
        await this.$question.setSurveySave();
      }

      if (this.modalName === QUESTION.MODAL_TYPE.END && !emit) {
        const url = this.surveyType === 1 ? `/project/payment/${this.projectId}` : `/project/quota/${this.projectId}`;
        await this.$router.push(url);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
      this.checkTitle = false;
    }
  }

  get checkTitle() {
    return this.titleCheck;
  }

  set checkTitle(payload: boolean) {
    this.titleCheck = payload;
  }

  // 편집 모드일 경우 하단 버튼 그룹 숨김
  editOn() {
    return (
      this.makeList.filter((question) => {
        const { NAME } = question;
        return question.EDIT && QUESTION.FIXED_QUESTIONS.indexOf(NAME) === -1;
      }).length > 0
    );
  }

  //문항 스크롤
  questionSwitch({ QNUM: QNAME, naviScroll, notOpen }: { QNUM: string; naviScroll: boolean; notOpen?: boolean }) {
    this.dataName = '';
    if (QNAME === this.dataName) return;
    this.naviScroll = naviScroll;
    this.dataName = QNAME;
    this.scrolling = true;
    this.makeList = this.makeList.map((m) => {
      m.EDIT = false;
      return m;
    });
    const index = this.makeList.findIndex((f) => f.NAME === QNAME);
    if (this.makeList[index].EDIT) return;
    if (!notOpen) {
      this.makeList[index].EDIT = true;
    }

    setTimeout(() => {
      const ele = document.getElementById(`view-${QNAME}`);
      if (ele) {
        ele.scrollIntoView({
          behavior: naviScroll ? `auto` : 'smooth',
          block: 'start',
        });
      }
    }, 100);
  }

  //네비게이션 스크롤
  handleScroll(e) {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = window.setTimeout(() => {
      if (this.scrolling) {
        this.navigationScroll();
      }
    }, 100);
  }

  navigationScroll() {
    if (!this.naviScroll) return;
    const ele = document.getElementById(`make-${this.dataName}`);
    const content = document.getElementById('listNavi');
    if (ele && content) {
      const docViewTop = content.scrollTop;
      const docViewBottom = docViewTop + content.clientHeight;
      const elemTop = ele.offsetTop;
      const elemBottom = elemTop + ele.clientHeight;
      const isVisible = elemBottom <= docViewBottom && elemTop >= docViewTop;

      if (!isVisible) {
        ele.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    }
    this.scrolling = false;
  }

  // 라우터 체크
  beforeRouteLeave(to: any, from: any, next: any) {
    const edit = this.makeList.filter(v =>  v.EDIT);
    if (edit.length > 0) {
      const emit = {
        type: QUESTION.MODAL_TYPE.LEAVE,
        qnum: '',
        title: '문항 편집을 종료하시겠습니까?',
        description: '[설문 저장]을 하지 않으면 변경사항이 반영되지 않습니다. <br/> 변경사항을 저장했는지 확인해주세요.'
      };

      this.showConfirmModal(emit).then((confirmed) => {
        if (confirmed) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  }

  showConfirmModal(emit) {
    return new Promise((resolve) => {
      this.modalController(emit);
      this.$once('modal-confirm', () => {
        resolve(true);
        this.$bvModal.hide(`modal-leave`);
      });

      this.$once('modal-cancel', () => {
        resolve(false);
        this.$bvModal.hide(`modal-leave`);
      });
    });
  }
}
