
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import QuestionLists from '@/components/project/make/question/QuestionLists.vue';
import { IQuestionValue } from '@/interface/survey/question';

@Component({
  components: {
    QuestionLists,
  },
})
export default class Question extends Vue {
  @Prop({ default: [] }) readonly list: IQuestionValue[];
  @Prop({ default: '' }) readonly currentDataName: string;
  @Prop() readonly questionName: string[];
  @Prop() readonly loading: boolean;

  cardClass(name: string) {
    const isEqualName = this.currentDataName === name;
    const defaultClass = '';
    const addPrimaryClass = defaultClass + ' selected-question';
    return isEqualName ? addPrimaryClass : defaultClass;
  }

  questionSwitch(QNAME) {
    this.$emit('questionSwitch', { QNUM: QNAME, naviScroll: true });
  }

  @Emit('modalController')
  modalController(emit: { type: string; qnum: string }) {
    return emit;
  }
}
